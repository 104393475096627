import React, { useEffect, useState } from "react"
import { graphql, Link } from 'gatsby';
import {
  Layout,
  SEO,
  PlanBnr,
  Access,
  ImgList,
  InfoTable,
  FacilityPostBtns,
  FacilityDNav,
  KitchenList,
  AmenitieList,
  FaqList,
  wovnCheck,
} from '../../../components/_index';


// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter
  const [floorPdf, setFloorPdf] = useState('floorPlan_gokomachi-ebisugawa_ja.pdf')
  wovnCheck(function (wovnCode: string) {
    const pdffile = wovnCode !== 'ja' ? 'floorPlan_gokomachi-ebisugawa_en.pdf' : 'floorPlan_gokomachi-ebisugawa_ja.pdf';
    setFloorPdf(pdffile)
  });
  return (
    <Layout>
      <SEO title={frontmatter?.title}
        description={frontmatter?.description}
      />
      <main id="facility_d" className="nishijin-villa under">
        <div id="facility_dMv">
          <div className="mvCont">
            <p className="ico">#3</p>
            <h2 className="facility_dMvTit">京の温所 御幸町夷川<span>GOKOMACHI-EBISUGAWA</span></h2>
            <div className="mvImg" style={{ backgroundImage: 'url(/images/facility/gokomachi20210919.jpg)' }}></div>
          </div>
          <FacilityDNav data={[
            {
              anchor: "#facility_dInfo",
              label: <>施設情報</>,
            },
            {
              anchor: "#facility_dAmenitie",
              label: <>アメニティ</>,
            },
            {
              anchor: "#facility_dAccess",
              label: <>アクセス</>,
            },
            {
              anchor: "#facility_dPlan",
              label: <>プラン一覧</>,
            },
            {
              anchor: "#facility_dFaq",
              label: <>よくあるご質問</>,
            },
          ]} />
        </div>
        <div id="facility_dWrap" className="wd">
          <div id="facility_dInfoWrap">
            <section id="facility_dInfoAbout">
              <h2 className="tit">用と美 「飲む」「食べる」ひとときのための道具や骨董品が揃う<br />風情ただよう、茶房のような京町家</h2>
              <p>茶道をこころゆくまで気楽に楽しみたい。<br />そんな想いから約90年前に建てられた京町家。茶道の香りがただよう数寄屋造の意匠はそのままに、現代の生活環境に寄り添うようにリノベーションしました。<br />お茶の時間が楽しくなる茶碗や、京都ならではの「おばんざい」を盛るための美しい骨董の皿も揃えています。<br />京都市内どこへ行くにも便利な御所の南に位置し、近くには珈琲豆専門店や喫茶店、地元の人に愛される菓子店もあります。</p>
              <FacilityPostBtns data={[
                {
                  href: `/assets/files/facility/${floorPdf}`,
                  blank: true,
                  label: <>間取り図<small>（PDF）</small></>,
                },
                {
                  href: "/news/recommend/#gokomachi-ebisugawa",
                  blank: false,
                  label: <>周辺のおすすめ</>,
                },
              ]} />
            </section>
            <section id="facility_dInfo">
              <h2 className="tit">INFORMATION</h2>
              <p className="post">京の温所 御幸町夷川 #3<br />
                <a href="https://goo.gl/maps/EzSG3t5sMcB2" target="_blank">京都市中京区御幸町通夷川上る松本町574</a>
              </p>
              <InfoTable data={[
                {
                  label: <>広さ</>,
                  value: <>2階建て82平米（1棟貸切）</>,
                },
                {
                  label: <>定員</>,
                  value: <>1～4名</>,
                },
                {
                  label: <>部屋</>,
                  value: <>&lt;1階&gt; キッチン＆和室ダイニング（掘りごたつ） 和室　庭　風呂（琺瑯）　洗面　トイレ　<br />&lt;2階&gt; ベッドルーム　トイレ</>,
                },
                {
                  label: <>寝具</>,
                  value: <>セミダブルベッド2台　ふとん2組（3名よりご用意）</>,
                },
                {
                  label: <>設備</>,
                  value: <>エアコン、空気清浄機、洗濯機、掘りごたつ、床暖房</>,
                },
                {
                  label: <>テレビ</>,
                  value: <>あり（19インチ ポータブルタイプ）<br />※インターネット、機器等との接続不可</>,
                },
                {
                  label: <>スピーカー</>,
                  value: <>Bluetoothスピーカー</>,
                },
                {
                  label: <>Wi-Fi</>,
                  value: <>無料Wi-Fi（無線LAN）</>,
                },
                {
                  label: <>禁煙</>,
                  value: <>建物・敷地内　全面禁煙</>,
                },
              ]} />
            </section>
          </div>
          <div id="facility_dGallery" className="facility_dSection">
            <ImgList data={[
              '/images/facility/27cf8013775ac67028af606346ae976e.jpg',
              '/images/facility/gokomachi-ebisugawa01.jpg',
              '/images/facility/20210919desk.jpg',
              '/images/facility/gokoebisu_034.jpg',
              '/images/facility/20220611sara.jpg',
              '/images/facility/gokomachi-ebisugawa02.jpg',
              '/images/facility/20220611gokobath.jpg',
              '/images/facility/20220611gokocho.JPG',
              '/images/facility/20210919bedroomgoko.jpg',
            ]} />
          </div>
          <section id="facility_dIntro" className="facility_d facility_dSection">
            <h2 className="facility_dTit">茶室のつくりを生かしたちいさなライブラリー</h2>
            <p>表門をくぐるとすぐ右手にある和室「オモテノマ」。こだわり抜いた茶室のようなつくりなのに、温かくやさしく包み込まれるような空間は、この京町家を建てたご隠居さまが愛した部屋です。<br />雪見障子をはじめ、当時の天井、柱をそのままに数寄屋造の美しさが詰まったちいさな部屋に、「飲む」「呑む」をテーマにしたライブラリーをつくりました。知とやすらぎの時間をゆったりとお過ごしください。
            </p>
            <ImgList data={[
              '/images/facility/e6e45357cb7763a9f739a61062602975.jpg',
              '/images/facility/dd1d42db14510d9a456c0f6c50bceb2a.jpg',
              '/images/facility/40333f4d6083fc5510dd555bdd47caaf.jpg',
            ]} />
          </section>
          <section id="facility_dKitchen" className="facility_d facility_dSection">
            <div>
              <h2 className="facility_dTit">「飲む」「食べる」 を楽しむための道具や器</h2>
              <p>キッチンの棚には、緑茶、珈琲、紅茶などのお茶の時間が楽しくなる道具を揃えました。
                <br />また、料理や盛り付けが楽しくなる、用と美を兼ね備えた調理器具や、京都の骨董市で見つけた大小さまざまな印判皿をご用意しています。<br />京町家の台所のかまど「おくどさん」があった位置につくったキッチンは、光を取り込み、かまどの煙や、煮炊きものの湯気を逃がすための吹き抜け「火袋」を生かしています。<br />掘りごたつのダイニングは、キッチンに立つ人とのおしゃべりが弾むよう目線が合う設計にしました。
              </p>
            </div>
            <ImgList data={[
              '/images/facility/20210919gokomachitana.JPG',
              '/images/facility/20220611gokoswe.jpg',
              '/images/facility/20210919wacoal21081803w.jpg',
            ]} />
            <KitchenList />
          </section>
          <AmenitieList data={[
            '/images/facility/50c7beb2cfff1f13219f62527dc3c67d_1.jpg',
            '/images/facility/amenities_bath.jpg',
            '/images/facility/amenities_pajamas.jpg',
          ]} />
          <section id="facility_dAccess">
            <Access data={{
              train: <>地下鉄東西線「京都市役所前」駅「丸太町」駅ゼスト御池口　徒歩約9分<br />地下鉄烏丸線「丸太町」駅5番出口　徒歩約11分</>,
              bus: <>「河原町丸太町」下車　徒歩約5分</>,
              taxi: "",
              parking: <>駐車場はございません。（近隣パーキング：徒歩5分圏内数か所あり）</>,
              img: "",
              maplink: "https://goo.gl/maps/EzSG3t5sMcB2",
              map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3267.6650871531847!2d135.76420511524074!3d35.015087980354934!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6001095d3cf7adcb%3A0x63e010ae69eb3ecd!2z5Lqs44Gu5rip5omAIOW-oeW5uOeUuuWkt-W3nQ!5e0!3m2!1sja!2sjp!4v1545109199603",
            }} />
          </section>
          <section id="facility_dPlan">
            <PlanBnr roomcd="0000001667" />
          </section>
          <FaqList />
        </div>
      </main>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!){
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
